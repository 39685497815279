export async function serviceDeskPageLoader() {
  const servicedeskResponse = await fetch("/api/servicedesk", {
    headers: {
      "init-data": window.Telegram.WebApp?.initData,
    },
  });

  if (servicedeskResponse.status !== 200) {
    throw servicedeskResponse;
  }

  const customerResp = await fetch("/api/customer", {
    headers: {
      "init-data": window.Telegram.WebApp?.initData,
    },
  });

  if (customerResp.status > 201) {
    throw customerResp;
  }

  return {
    servicedesks: await servicedeskResponse.json(),
    customer: await customerResp.json(),
  };
}

export async function requesttypePageLoader({ params }) {
  const requesttypesResp = await fetch(
    `/api/servicedesk/${params.serviceDeskId}/requesttype`,
    {
      headers: {
        "init-data": window.Telegram.WebApp?.initData,
      },
    }
  );

  if (requesttypesResp.status !== 200) {
    throw requesttypesResp;
  }

  return { requesttypes: await requesttypesResp.json() };
}

export async function fieldPageLoader({ params }) {
  const fieldsResp = await fetch(
    `/api/servicedesk/${params.serviceDeskId}/requesttype/${params.requestTypeId}/field`,
    {
      headers: {
        "init-data": window.Telegram.WebApp?.initData,
      },
    }
  );

  if (fieldsResp.status !== 200) {
    throw fieldsResp;
  }

  return { fields: await fieldsResp.json() };
}

export async function feedbackPageLoader({ params }) {
  const customerResp = await fetch("/api/customer", {
    headers: {
      "init-data": window.Telegram.WebApp?.initData,
    },
  });

  if (customerResp.status > 201) {
    throw customerResp;
  }

  const customer = await customerResp.json();

  const fieldsResp = await fetch(`/api/feedback/${params?.type}`, {
    headers: {
      "init-data": window.Telegram.WebApp?.initData,
    },
  });

  if (fieldsResp.status !== 200) {
    throw fieldsResp;
  }

  return { ...(await fieldsResp.json()), customer };
}
