import React, { Fragment, useEffect, useCallback, useState } from "react";

import { DropZone } from "../components/DropZone";
import { FileList } from "../components/FileList";

import TextField from "@atlaskit/textfield";
import TextArea from "@atlaskit/textarea";
import { useLoaderData } from "react-router-dom";

import { DatePicker, DateTimePicker } from "@atlaskit/datetime-picker";

import Select from "@atlaskit/select";
import { CreatableSelect, CheckboxSelect } from "@atlaskit/select";
import ButtonGroup from "@atlaskit/button/button-group";
import LoadingButton from "@atlaskit/button/loading-button";
import Button from "@atlaskit/button/standard-button";

import Form, {
  ErrorMessage,
  Field,
  FormFooter,
  FormSection,
  HelperMessage,
} from "@atlaskit/form";

import PageHeader from "@atlaskit/page-header";

import { useTelegram } from "../hooks/useTelegram";
import "../App.css";
import {
  TXT_CANCEL,
  TXT_INVALID_VALUE,
  TXT_NO_FIELDS_AVAILABLE,
  TXT_SEND,
  TXT_VALUE_REQUIRED,
} from "../i8n";

const FeedbackPage = () => {
  const { tg } = useTelegram();
  const { servicedesk, requesttype, customer, fields, isAnonymous } =
    useLoaderData();
  const lang = customer.language || "en";

  const accept = undefined;
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const prepareOptions = (options) =>
    options.map((option) => {
      if (!option.id) {
        option.id = option.value;
      }
      if (!option.label) {
        option.label = option.value;
      } else {
        option.value = option.label;
      }
      return option;
    });

  useEffect(() => {
    tg.expand();
  }, [tg]);

  const validate = useCallback(
    (value) => {
      if (value?.length === 0) {
        return TXT_VALUE_REQUIRED[lang];
      }
      if (!value) {
        return TXT_VALUE_REQUIRED[lang];
      }
      return;
    },
    [lang]
  );

  const onBackPressed = useCallback(() => {
    tg.close();
  }, [tg]);

  const handleOnChange = useCallback(
    async (newFiles) => {
      setFileList((old) => [
        ...old,
        ...Array.from(newFiles).map((file) => ({
          id: crypto.randomUUID(),
          uploaded: false,
          failed: false,
          file,
        })),
      ]);
      setUploading(true);

      const data = new FormData();

      for (const file of newFiles) {
        data.append("attachment", file);
      }

      console.log("---");
      console.log(data.getAll("attachment"));

      const response = await fetch(
        `/api/servicedesk/${servicedesk.id}/attachTemporaryFiles`,
        {
          method: "post",
          body: data,
          headers: {
            "init-data": tg.initData,
          },
        }
      );

      const ids = await response.json();
      console.log(ids);

      setFileList((old) =>
        old.map((file) => {
          if (!file.temporaryAttachmentId) {
            const uploaded = ids?.find((e) => e.fileName === file.file.name);
            file.uploaded = true;
            if (uploaded) {
              file.temporaryAttachmentId = uploaded.temporaryAttachmentId;
            } else {
              file.failed = true;
            }
          }
          return file;
        })
      );
      setUploading(false);
    },
    [servicedesk.id, tg]
  );

  const handleClearFile = useCallback((id) => {
    setFileList((prev) => prev.filter((file) => file.id !== id));
  }, []);

  useEffect(() => {
    if (fileList.length < 1) {
      setUploading(false);
    }
  }, [fileList.length]);

  useEffect(() => {
    tg.onEvent("backButtonClicked", onBackPressed);
    return () => {
      tg.offEvent("backButtonClicked", onBackPressed);
    };
  }, [tg, onBackPressed]);

  return (
    <Form
      onSubmit={async (formData) => {
        if ("attachment" in formData) {
          formData.attachment = fileList
            .map((file) => file.temporaryAttachmentId)
            .filter((id) => !!id);
        }
        console.log("Form data:", formData);

        const response = await fetch(
          `/api/servicedesk/${servicedesk.id}/requesttype/${requesttype.id}/create`,
          {
            method: "post",
            headers: {
              "init-data": tg.initData,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              formData,
              requestTypeName: requesttype.name,
              isAnonymous: isAnonymous,
            }),
          }
        );

        if (response.status > 201) {
          throw response;
        }

        return await response.json();
      }}
    >
      {({ formProps, submitting }) => (
        <form {...formProps} style={{ padding: "16px" }} method="post">
          <div className="HeaderWrapper">
            <PageHeader>{requesttype.name}</PageHeader>
          </div>
          <FormSection>
            {fields.requestTypeFields.length > 0 ? (
              fields.requestTypeFields.map((item) => {
                if (!item.visible) return null;

                if (item.jiraSchema.type === "string") {
                  if (
                    item.jiraSchema?.system === "description" ||
                    item.jiraSchema?.custom?.endsWith("textarea")
                  )
                    return (
                      <Field
                        key={item.fieldId}
                        aria-required={true}
                        name={item.fieldId}
                        label={item.name}
                        isRequired={item.required}
                        defaultValue=""
                        validate={item.required && validate}
                      >
                        {({ fieldProps, meta: { valid, error } }) => (
                          <Fragment>
                            <TextArea {...fieldProps} minimumRows={5} />
                            <HelperMessage>{item.description}</HelperMessage>
                            {!valid && (
                              <ErrorMessage>
                                {error || TXT_INVALID_VALUE[lang]}
                              </ErrorMessage>
                            )}
                          </Fragment>
                        )}
                      </Field>
                    );

                  return (
                    <Field
                      key={item.fieldId}
                      aria-required={true}
                      name={item.fieldId}
                      label={item.name}
                      isRequired={item.required}
                      defaultValue=""
                      validate={item.required && validate}
                    >
                      {({ fieldProps, meta: { valid, error } }) => (
                        <Fragment>
                          <TextField {...fieldProps} autoComplete="off" />
                          <HelperMessage>{item.description}</HelperMessage>
                          {!valid && (
                            <ErrorMessage>
                              {error || TXT_INVALID_VALUE[lang]}
                            </ErrorMessage>
                          )}
                        </Fragment>
                      )}
                    </Field>
                  );
                }

                if (item.jiraSchema.type === "array") {
                  if (item.jiraSchema.system === "attachment")
                    return (
                      <Field
                        key={item.fieldId}
                        name={item.fieldId}
                        label={item.name}
                        isRequired={item.required}
                        defaultValue={fileList}
                        validate={item.required && validate}
                      >
                        {({ fieldProps, meta: { valid, error } }) => (
                          <Fragment>
                            <div>
                              <DropZone
                                {...fieldProps}
                                onChange={handleOnChange}
                                accept={accept}
                                lang={lang}
                                required={item.required}
                              />
                            </div>
                            {!valid && (
                              <ErrorMessage>
                                {error || TXT_INVALID_VALUE[lang]}
                              </ErrorMessage>
                            )}
                            {fileList.length ? (
                              <div>
                                <FileList
                                  files={fileList}
                                  onClear={handleClearFile}
                                />
                              </div>
                            ) : null}
                            <br />
                          </Fragment>
                        )}
                      </Field>
                    );

                  if (
                    item.jiraSchema.customId &&
                    item.jiraSchema.items !== "user"
                  )
                    return (
                      <Field
                        key={item.fieldId}
                        name={item.fieldId}
                        label={item.name}
                        isRequired={item.required}
                        defaultValue={prepareOptions(item.defaultValues)}
                        validate={item.required && validate}
                      >
                        {({ fieldProps, meta: { valid, error } }) => (
                          <Fragment>
                            {item.validValues.length > 0 ? (
                              <CheckboxSelect
                                isMulti={true}
                                isClearable={true}
                                {...fieldProps}
                                inputId={fieldProps.id}
                                options={prepareOptions(item.validValues)}
                              />
                            ) : (
                              <CreatableSelect
                                isMulti={true}
                                isClearable={true}
                                {...fieldProps}
                                inputId={fieldProps.id}
                                options={prepareOptions(item.validValues)}
                              />
                            )}
                            <HelperMessage>{item.description}</HelperMessage>
                            {!valid && (
                              <ErrorMessage>
                                {error || TXT_INVALID_VALUE[lang]}
                              </ErrorMessage>
                            )}
                          </Fragment>
                        )}
                      </Field>
                    );
                }

                if (
                  item.jiraSchema.type === "option" ||
                  item.jiraSchema.type === "priority"
                )
                  return (
                    <Field
                      key={item.fieldId}
                      name={item.fieldId}
                      label={item.name}
                      isRequired={item.required}
                      defaultValue={
                        item.jiraSchema.type === "array"
                          ? prepareOptions(item.defaultValues)
                          : prepareOptions(item.defaultValues)[0]
                      }
                      validate={item.required && validate}
                    >
                      {({ fieldProps, meta: { valid, error } }) => (
                        <Fragment>
                          <Select
                            {...fieldProps}
                            isClearable={true}
                            inputId={fieldProps.id}
                            options={prepareOptions(item.validValues)}
                            isMulti={item.jiraSchema.type === "array"}
                          />
                          <HelperMessage>{item.description}</HelperMessage>
                          {!valid && (
                            <ErrorMessage>
                              {error || TXT_INVALID_VALUE[lang]}
                            </ErrorMessage>
                          )}
                        </Fragment>
                      )}
                    </Field>
                  );

                if (item.jiraSchema.type === "date")
                  return (
                    <Field
                      key={item.fieldId}
                      name={item.fieldId}
                      label={item.name}
                      isRequired={item.required}
                      validate={item.required && validate}
                    >
                      {({ fieldProps, meta: { valid, error } }) => (
                        <Fragment>
                          <DatePicker
                            {...fieldProps}
                            inputId={fieldProps.id}
                            // isMulti
                          />
                          <HelperMessage>{item.description}</HelperMessage>
                          {!valid && (
                            <ErrorMessage>
                              {error || TXT_INVALID_VALUE[lang]}
                            </ErrorMessage>
                          )}
                        </Fragment>
                      )}
                    </Field>
                  );

                if (item.jiraSchema.type === "datetime")
                  return (
                    <Field
                      key={item.fieldId}
                      name={item.fieldId}
                      label={item.name}
                      isRequired={item.required}
                      validate={item.required && validate}
                    >
                      {({ fieldProps, meta: { valid, error } }) => (
                        <Fragment>
                          <DateTimePicker
                            {...fieldProps}
                            inputId={fieldProps.id}
                            // isMulti
                          />
                          <HelperMessage>{item.description}</HelperMessage>
                          {!valid && (
                            <ErrorMessage>
                              {error || TXT_INVALID_VALUE[lang]}
                            </ErrorMessage>
                          )}
                        </Fragment>
                      )}
                    </Field>
                  );

                return null;
              })
            ) : (
              <h4>{TXT_NO_FIELDS_AVAILABLE[lang]}</h4>
            )}
          </FormSection>
          <FormFooter>
            <ButtonGroup>
              <Button
                appearance="subtle"
                onClick={onBackPressed}
                isDisabled={submitting || uploading}
              >
                {TXT_CANCEL[lang]}
              </Button>
              <LoadingButton
                type="submit"
                appearance="primary"
                isLoading={submitting}
                isDisabled={fields.requestTypeFields.length === 0 || uploading}
              >
                {TXT_SEND[lang]}
              </LoadingButton>
            </ButtonGroup>
          </FormFooter>
        </form>
      )}
    </Form>
  );
};

export default FeedbackPage;
